<template>
  <div>
    <span class="material-input-bar" />
    <label class="material-label">
      <slot />
    </label>
    <br v-if="$slots['default']" />
    <el-table
      ref="restaurantSelector"
      :data="restaurants"
      border
      fit
      highlight-current-row
      style="width: 100%;"
      height="600"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="40" align="center" :selectable="() => true"></el-table-column>
      <el-table-column :label="$t('common.restaurant')" property="name">
        <template slot-scope="scope">
          <span>{{ scope.row['name'] }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { closeCurrentTag } from '@/utils/tags';

export default {
  name: 'RestaurantSelector',
  components: {},
  props: {
    value: {
      default() {
        return [];
      },
      type: [Array]
    }
  },
  data() {
    return {
      multipleSelection: [],
      currentValue: [],
      valueLoaded: false,
      restaurantsLoaded: false
    };
  },
  computed: {
    hasItemsSelected() {
      return !!this.multipleSelection.length;
    },
    restaurants() {
      return this.$store.getters['restaurant/loadedItems'];
    }
  },
  watch: {
    value(newValue) {
      this.currentValue = newValue;
      this.valueLoaded = true;
      if (this.restaurantsLoaded) {
        this.toggleRowSelection(this.currentValue);
      }
    }
  },
  async created() {
    await this.$store.dispatch('restaurant/getItems').then(() => {
      this.restaurantsLoaded = true;
      if (this.valueLoaded) {
        this.toggleRowSelection(this.currentValue);
      }
    });
  },
  methods: {
    close() {
      closeCurrentTag(this);
    },
    toggleRowSelection(selectedRestaurants) {
      this.restaurants.forEach(row =>
        this.$refs.restaurantSelector.toggleRowSelection(
          row,
          selectedRestaurants.some(restaurantID => restaurantID === row.id)
        )
      );
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      const restaurantIDs = this.multipleSelection.map(restaurant => {
        return restaurant.id;
      });
      this.$emit('input', restaurantIDs);
    },
    selectAllItems(rows) {
      rows.forEach(row => this.$refs.restaurantSelector.toggleRowSelection(row, true));
    },
    clearSelection() {
      this.$refs.restaurantSelector.clearSelection();
    }
  }
};
</script>
<style lang="scss"></style>
